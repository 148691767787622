import React from 'react';
import { graphql } from 'gatsby';
import { mapEdgesToNodes } from '../lib/helpers';
import Container from '../components/container';
import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/seo';
import Layout from '../containers/layout';
import { imageUrlFor } from '../lib/image-url';
import { buildImageObj } from '../lib/helpers';

export const query = graphql`
  query AboutPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      subtitle
      description
      keywords
    }
    imageSets: allSanityImageSet(filter: { imageSetName: { eq: "Om oss" } }) {
      edges {
        node {
          id
          imageSetName
          images {
            _key
            _type
            asset {
              _id
            }
          }
        }
      }
    }
  }
`;

const AboutPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const galleryNodes = (data || {}).imageSets ? mapEdgesToNodes(data.imageSets) : [];

  const aboutUsGallery = galleryNodes[0];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <SEO title="Om oss" description={site.description} keywords={site.keywords} />
      <Container>
        <h1>Om oss</h1>
        <p>
          Butikken ble etablert i 1946, den gang med produksjon av vannslanger og skumgummi. I 1994 begynte vi med
          interiør. I dag drives butikken i 3 generasjon, i de samme lokalene, rett ved Herkules kjøpesenter.
        </p>

        {aboutUsGallery && aboutUsGallery.images.map(im => <img src={imageUrlFor(buildImageObj(im))} />)}
      </Container>
    </Layout>
    /*
    {galleryNodes &&
      galleryNodes[0].images.map(im => (
          <img
          src={imageUrlFor(buildImageObj(im.asset))
          .width(600)
          .height(Math.floor((9 / 16) * 600))
          .url()}
          
      />
    ))}
    */
  );
};

export default AboutPage;
